<!-- eslint-disable no-undef -->
<template>
  <div class="CalendarPage page">
    <Tabs :array="header" :select-id-in="selectId" v-model="selectId" />
    <div class="CalendarPage__body">
      <CalendarMain
        @updateDate="updateDate"
        :attributesCalendar="attributesCalendar"
        :сategories="selectId"
        @dayclick="dayclick"
      />
    </div>
    <div class="CalendarPage__footer container">
      <van-button
        type="default"
        class="button"
        @click="routeWithSameQuery('Chart')"
        >{{ this.$t("common.views_charts") }}</van-button
      >
      <div class="CalendarPage__footer-buttons">
        <!-- <van-button type="primary" class="button">{{ this.$t('calendar.button1')}}</van-button> -->
        <van-button
          type="primary"
          class="button"
          @click="closeTelegramWindow"
          >{{ this.$t("calendar.button2") }}</van-button
        >
        <!-- <van-button type="primary" class="button">{{ this.$t('calendar.button3')}}</van-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CalendarMain from "@/components/Calendar";
import Tabs from "@/components/Tabs";
import globalFunctions from "@/mixins/globalFunctions";

export default {
  name: "Calendar",
  components: {
    CalendarMain,
    Tabs,
  },
  mixins: [globalFunctions],
  data() {
    return {
      header: [
        { title: this.$t("calendar.headerTitleOne"), id: 0 },
        { title: this.$t("calendar.headerTitleTwo"), id: 1 },
      ],
      attributes: [
        {
          dot: {
            class: "s-green",
          },
          dates: [],
        },
        {
          dot: {
            class: "s-red",
          },
          dates: [],
        },
        {
          dot: {
            class: "s-light",
          },
          dates: [],
        },
        {
          dot: {
            class: "s-dark",
          },
          dates: [],
        },
        {
          dot: {
            class: "mood-1",
          },
          dates: [],
        },
        {
          dot: {
            class: "mood-2",
          },
          dates: [],
        },
        {
          dot: {
            class: "mood-3",
          },
          dates: [],
        },
        {
          dot: {
            class: "mood-4",
          },
          dates: [],
        },
        {
          dot: {
            class: "mood-5",
          },
          dates: [],
        },
        {
          dot: {
            class: "mood-6",
          },
          dates: [],
        },
        {
          dot: {
            class: "mood-7",
          },
          dates: [],
        },
        {
          dot: {
            class: "menstruation",
          },
          dates: [],
        },
      ],
      selectId: this.$route.query.type === "sleep" ? 0 : 1,
      keyCalendar: 0,
      dataAttributes: [],
      calendarDate: [],
    };
  },
  computed: {
    attributesCalendar() {
      return this.attributes;
    },
    ...mapGetters("History", ["history"]),
  },
  methods: {
    addAttributes(item) {
      let key;
      let element;
      for (element in this.attributes) {
        this.attributes[element].dates = [];
      }

      for (key in item) {
        let valueDate;
        let element = item[key];
        for (valueDate in element) {
          let value = element[valueDate];
          if (this.selectId == 0) {
            if (value.sleep_time >= 0 && value.sleep_time <= 6) {
              this.attributes
                .find((item) => item.dot.class === "s-dark")
                .dates.push(new Date(value.date));
            } else if (value.sleep_time >= 7 && value.sleep_time <= 10) {
              this.attributes
                .find((item) => item.dot.class === "s-green")
                .dates.push(new Date(value.date));
            } else if (value.sleep_time >= 11) {
              this.attributes
                .find((item) => item.dot.class === "s-light")
                .dates.push(new Date(value.date));
            }
          } else {
            this.attributes.find(
              (item) =>
                item.dot.class === "s-dark" ||
                item.dot.class === "s-green" ||
                item.dot.class === "s-light"
            ).dates = [];
            for (let i = 0; i <= 7; i++) {
              if (value.mood == i) {
                this.attributes
                  .find((item) => item.dot.class === "mood-" + i)
                  .dates.push(new Date(value.date));
              }
            }
            if (value.menstruation == 1) {
              this.attributes
                .find((item) => item.dot.class === "menstruation")
                .dates.push(new Date(value.date));
            }
          }
        }
      }
    },
    updateDate(date) {
      if (date.year && date.month) {
        let dateParms = `${date.year}${
          date.month >= 10 ? date.month : "0" + date.month
        }01`;

        axios
          .get(
            `https://hm.tgapp.live/records?token=${this.$route.query.token}&date=${dateParms}&direction=future`,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET,PUT,POST,DELETE,PATCH,OPTIONS",
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.addAttributes(res.data.data);
              this.dataAttributes = res.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    dayclick(data) {
      let target = this.dataAttributes[data];
      console.log(this.dataAttributes, data);
      if (target) {
        this.$store.commit("History/set_history", this.dataAttributes[data]);
        this.$store.commit("History/set_dayList", this.dataAttributes);

        this.$router.push({
          name: "History",
          query: { token: this.$route.query.token, selectId: this.selectId },
        });
      }
    },
  },
  watch: {
    selectId() {
      this.keyCalendar = this.keyCalendar + 1;
    },
  },
  mounted() {
    this.updateDate(new Date());
  },
};
</script>

<style scoped lang="scss">
.CalendarPage {
  .container {
    height: 100%;
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 72px;
    padding-bottom: 84px;
    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 31px;
      & > *:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
}
.button {
  width: fit-content;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  padding: 0 36px;
  font-size: 16px;
  border-radius: 40px;
  border: none;
}
.van-button--primary {
  background: #000;
  border-color: #000;
  color: #fff;
  max-width: 309px;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.van-button--normal {
  max-width: 309px;
  width: 100%;
}
</style>
